.btn-primary {
  background: #0056cd;
  border-radius: 5px;
  font-family: Poppins, BR Firma;

  font-style: normal;
  font-weight: 600;
  font-size: 1em;

  line-height: 1.25em;
  text-align: center;
  color: #ffffff;
  &:active {
    opacity: 0.9;
  }
}

.btn-secondary {
  background: #d6e7ff;
  border-radius: 5px;
  font-family: Poppins, BR Firma;

  font-style: normal;
  font-weight: 600;
  font-size: 1em;

  line-height: 1.25em;
  text-align: center;
  color: #0056cd;
  &:active {
    opacity: 0.9;
  }
}

.btn-md {
  // padding: 18px 30px;
  padding: 0.75em 1.7em;
  width: fit-content;
  // height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
