@font-face {
  font-family: "BR Firma";
  src: url("../../assets//fonts/br-firma-cufonfonts/BRFirmaThin.otf");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "BR Firma";
  src: url("../../assets//fonts/br-firma-cufonfonts/BRFirmaExtraLight.otf");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "BR Firma";
  src: url("../../assets//fonts/br-firma-cufonfonts/BRFirmaLight.otf");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "BR Firma";
  src: url("../../assets//fonts/br-firma-cufonfonts/BRFirmaRegular.otf");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "BR Firma";
  src: url("../../assets//fonts/br-firma-cufonfonts/BRFirmaMedium.otf");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "BR Firma";
  src: url("../../assets//fonts/br-firma-cufonfonts/BRFirmaSemiBold.otf");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "BR Firma";
  src: url("../../assets//fonts/br-firma-cufonfonts/BRFirmaBold.otf");
  font-style: normal;
  font-weight: 700;
}
/* @font-face {
  font-family: "BR Firma";
  src: url("../../assets//fonts/br-firma-cufonfonts/BRFirmaBlack.otf");
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "BR Firma";
  src: url("../../assets//fonts/br-firma-cufonfonts/BRFirmaThin.otf");
  font-style: normal;
  font-weight: 900;
} */
