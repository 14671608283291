@import "./styling/mainstyling";
@import "./styling/typography/fontfamily.css";

html,
body,
#root {
  height: 100% !important;
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  -webkit-font-smoothing: antialiased;

  @media only screen and (min-width: 768px) {
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background: #a1acaf;
      border-radius: 2px;
    }
    ::-webkit-scrollbar-track {
      background-color: #edeff2;
      border-radius: 2px;
    }
  }
}

input::-webkit-calendar-picker-indicator {
  opacity: 0.5;
}

.__floater__open {
  padding: 13 !important;
}

.css-79elbk {
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .css-42igfv {
    font-size: 1rem;
  }
}

#cryptoForm input:focus,
#collaborator input:focus {
  box-shadow: none !important;
}

.otp-details {
  background-color: white;
  position: absolute;
  bottom: 100px;
  left: 22px;
  right: 22px;
}

.shareleft {
  height: calc(78vh - 310px);
  padding-right: 8px;
  overflow-y: auto;

  @media only screen and (max-width: 550px) {
    height: calc(100vh - 350px);
    padding-right: unset;
    &.folder {
      height: calc(100vh - 290px);
    }
  }
}

:focus-visible {
  outline: unset;
}

input[type="text"]:focus {
  --tw-ring-color: none !important;
}

// Welcome Carousel
.carousel-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  // hide all scrollbars
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.carousel-item {
  flex: 0 0 auto;
  width: 100%;
  scroll-snap-align: start;
}