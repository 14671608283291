.acc-container {
  .resync {
    .__react_component_tooltip {
      max-width: 200px !important;
    }
    svg {
      animation: rotateAnimation 1.5s linear infinite;
    }
  }

  .table {
    width: 100%;
    margin-bottom: 22px;

    .table-account {
      display: grid;
      grid-template-columns: 3% 42% 21% 8% 20% 6%;
      align-items: center;
      padding: 12px;

      .edit-name {
        max-width: 90%;
      }

      &.highlight {
        border: 2px solid #0056cd;
        box-shadow: 0px 4px 24px rgba(17, 94, 200, 0.16);
        background: rgba(0, 86, 205, 0.2);
      }

      &:hover {
        box-shadow: -4px 0 0 0 #0056cd;
      }
    }

    .labels-grid {
      grid-template-columns: 35px 3fr 0.5fr 2fr 60px !important;
    }

    .table-grid {
      display: grid;
      grid-template-columns: repeat(7, minmax(75px, 1fr));
      text-align: center;
      padding: 10px;
    }

    .row-header {
      background: #edeff2;

      p {
        font-size: 14px;
        color: #4f5d6d;
      }
    }

    .row-content {
      border-bottom: 1px solid #edeff2;

      &:hover {
        background: #f7f9fc;
      }

      p {
        color: #1e2329;
        font-size: 14px;
      }
    }

    .tooltipwidth .__react_component_tooltip {
      max-width: 160px !important;
      padding: 8px !important;
    }

    .label-modal {
      right: 50px;
      top: 20px;
      box-shadow: 0px 9px 20px 6px rgba(79, 93, 109, 0.2);
      border-radius: 4px;

      img {
        top: 8px;
      }

      input {
        border-bottom: 0.5px solid #abafaf;
        font-size: 8px;
        &:focus {
          outline: none;
        }
      }

      ul {
        max-height: 190px;
        overflow-y: auto;

        li {
          padding: 5px;
          margin-bottom: 0.5rem;
          cursor: pointer;
          &:hover {
            background: #ebf3ff;
          }
        }

        li:last-of-type {
          margin-bottom: 0px;
        }
      }
    }

    .shadow {
      box-shadow: 0px 6px 8px 6px rgba(157, 170, 184, 0.17);
    }

    .edit-label {
      > div {
        width: 100%;
      }
      .input-field {
        border: 1px solid transparent;
        border-radius: 8px;
        transition: all 0.3s linear;
        padding-right: 8px;
        width: fit-content;
        &:hover {
          border-color: #0045a4 !important;
        }
      }
      svg {
        width: 20px;
        height: 20px;
        margin-left: 8px;
      }
      .input-active {
        max-width: 400px !important;
        @media only screen and (max-width: 1480px) {
          max-width: 250px !important;
        }
        @media only screen and (max-width: 1250px) {
          max-width: 210px !important;
        }
        @media only screen and (max-width: 1100px) {
          max-width: 180px !important;
        }
      }
      input {
        background: transparent;
        text-transform: capitalize;
        padding: 4px 0 4px 4px;
        border: none;
        max-width: 600px;
        min-width: 120px;
        box-sizing: content-box;
        &:focus {
          --tw-ring-shadow: 0 0 #000;
          --tw-ring-color: transparent;
        }
        @media only screen and (max-width: 1480px) {
          max-width: 440px;
        }
        @media only screen and (max-width: 1250px) {
          max-width: 390px;
        }
        @media only screen and (max-width: 1100px) {
          max-width: 290px;
        }
      }
    }
  }
}

.mobile-assets-list {
  margin: auto;
  border: 1px solid #f6f7f9;
  box-shadow: 0px 4px 12px rgba(79, 93, 109, 0.13);
  border-radius: 5px;

  .logo {
    width: 25px;
    height: 25px;
  }

  small {
    font-size: 10px;
    color: #4f5d6d;

    span {
      color: #4f5d6d;
      font-size: 8px;
      background: #edeff2;
      border-radius: 2px;
      padding: 2px;
    }
  }

  .div2q3 {
    border-bottom: 1px solid #edeff2;
    margin: 0 -12px;
  }

  .row {
    border-bottom: 1px solid #e1e7ec;
    padding-top: 14px;
    width: 95%;
    margin: auto;

    &:last-child {
      border-bottom: unset;
    }
  }

  .l-sync {
    color: #abafaf;
  }
}

@media only screen and (max-width: 668px) {
  .table-total {
    padding: 12px;
    font-size: 12px !important;
  }
}

.table-wr-sm {
  @media only screen and (min-width: 669px) {
    display: none;
  }
}

.table-wr-lg {
  @media only screen and (max-width: 668px) {
    display: none;
  }
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
