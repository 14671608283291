.sort-container {
  display: none;
  position: absolute;
  right: 0;
  padding: 24px 16px;
  background: #ffffff;
  box-shadow: 0px 9px 20px 6px rgba(157, 170, 184, 0.2);
  border-radius: 8px;
  min-width: 300px;
  z-index: 400;

  @media only screen and (max-width: 768px) {
    min-width: 240px;
    padding: 16px 12px;
  }

  &.is-open {
    display: block;
  }

  .label {
    color: #4f5d6d;

    @media only screen and (max-width: 568px) {
      font-size: 14px;
    }
  }
}
