// Tabs
// TODO: replace with applied styles from tailwind
.react-tabs__tab-list .react-tabs__tab--selected {
  background-color: rgb(163 163 163) !important; // bg-neutral-400
  color: rgb(255 255 255) !important; // text-neutral-100
}

.settingspage {
  .changepassword {
    &-header {
      p {
        color: #1e2329;
      }
    }

    .passwordinfoform {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 1.6875rem;
      .passwordform {
        form {
          width: 23.75rem;
          @media only screen and (max-width: 600px) {
            width: 23.75rem;
          }
        }
      }
      .passwordforminfos {
        background: #ebf3ff;
        border-radius: 0.625rem;
        width: 20rem;
        padding: 1.3125rem;

        .passwordinfosdiv {
          .forprotection {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 600;
            font-size: 0.8125rem;
            line-height: 1.25rem;
            margin: 0.625rem 0rem !important;
          }
          .inorder {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: normal;
            font-size: 0.8125rem;
            line-height: 1.25rem;

            /* Type:/Primary Type */

            color: #1e2329;
            margin-bottom: 0.625rem !important;
          }
          .passwordinfo12 {
            display: flex;
            align-items: center;
            margin-top: 0.1875rem !important;
            margin-bottom: 0.1875rem !important;
            span {
              margin-right: 0.3125rem;
            }
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: normal;
            display: flex;
            align-items: flex-end;
            color: #1e2329;
          }
        }
      }

      @media only screen and (max-width: 890px) {
        flex-direction: column-reverse;
        align-items: stretch;

        .passwordforminfos {
          width: 24.375rem;
          display: flex;
          .passwordlockicon {
            margin-right: 1.0625rem;
          }
        }
        .passwordform {
          margin-top: 1.875rem;

          form {
            width: 100%;
          }
        }
      }
      @media only screen and (max-width: 410px) {
        .passwordforminfos {
          width: 100%;
        }
        button {
          width: 100%;
        }
      }
      @media only screen and (max-width: 500px) {
        button {
          width: 100%;
        }
      }
    }
  }

  .userprofile-tab {
    .pheader {
      p {
        color: #1e2329;
      }
    }
    .profilepicdiv {
      display: flex;
      align-items: center;
      margin-top: 1.25rem;
      .imgss-01 {
        width: 100px;
        height: 100px;
        border-radius: 100%;
      }
      img {
        // width: 80%;
        margin-right: 0.625rem;
      }
      p {
        color: #0056cd;
      }
    }

    .usernameform {
      margin-top: 2.5rem;
      padding-bottom: 2rem;
      border-bottom: 0.0625rem solid #edeff2;
      .form-control {
        width: 33.125rem;
      }
    }

    .changeemailform {
      margin-top: 1.625rem;
      .emailformcontainer {
        margin-top: 0.9375rem;
        // display: flex;
        // align-items: center;
        padding-bottom: 2rem;
        // border-bottom: 0.0625rem solid #edeff2;

        .inputholder12 {
          // margin-right: .9375rem;
          .form-control {
            width: 33.125rem;
          }
        }
        .changeemailt {
          p {
            color: #0056cd;
          }
        }
      }
    }

    .deleteaccountdiv {
      margin-top: 1.625rem;
      .deletesmessage {
        width: 33.125rem;
        margin-top: 1.5rem;
        p {
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: normal;
          font-size: 0.938em;
          line-height: 1.1875rem;

          color: #4f5d6d;
        }
      }

      .deletebutton {
        margin-top: 0.75rem;
        button {
          transition-duration: 0.6s;
          background: transparent;
          &:hover {
            background: rgba($color: #ee425a, $alpha: 0.2);
          }
          color: #ee425a;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .usernameform {
        .form-control {
          width: 100%;
        }
      }
      .changeemailform {
        .emailformcontainer {
          .inputholder12 {
            // margin-right: .9375rem;
            .form-control {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .notificationstab {
    &-header {
      p {
        color: #1e2329;
      }
    }

    .intrigeers1 {
      margin-top: 1.3125rem;
      p {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 600;
        font-size: 1em;
        line-height: 1.25rem;
        color: #1e2329;
      }
    }

    .notifimains {
      display: flex;
      justify-content: space-between;
      .notifimains-first {
        .borderpp {
          border-top: 0.0625rem solid #edeff2;
          border-bottom: 0.0625rem solid #edeff2;
        }
        .selct-notif-layer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 33.125rem;
          @media only screen and (max-width: 600px) {
            width: 100%;
          }
          padding: 1.625rem 0rem 1.125rem;
          .selct-notif-layer-1 {
            width: 24.375rem;
            @media only screen and (max-width: 600px) {
              width: unset;
              margin-right: 1.875rem;
            }
            .ppp {
              color: #1e2329;
              margin-bottom: 0.4375rem !important;
            }
            .ppp-lll {
              font-family: Poppins, BR Firma;
              font-style: normal;
              font-weight: normal;
              font-size: 0.813em;
              line-height: 1rem;
              color: #4f5d6d;
            }
          }
          .selct-notif-layer-2 {
            display: flex;
            align-items: center;

            .day999 {
              font-family: Poppins, BR Firma;
              font-style: normal;
              font-weight: normal;
              font-size: 0.938em;
              line-height: 1.1875rem;
              color: #1e2329;
            }
          }
        }
      }
      .notifimains-second {
        width: 21.625rem;
        height: 24.8125rem;
        background: #ebf3ff;
        border-radius: 0.625rem;
        padding: 1.25rem 1.25rem 1.125rem;
        .emailicon {
          margin-bottom: 0.625rem;
        }
        .whowewill {
          margin-top: 0.9375rem;
          margin-bottom: 0.9375rem;
          p {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 600;
            font-size: 0.938em;
            line-height: 1.1875rem;
            color: #1e2329;
          }
        }

        .steps900 {
          display: flex;
          margin-bottom: 1.5rem;
          .icon34 {
            padding-top: 0.3125rem;
            margin-right: 1.0625rem;
          }
          .infos2000 {
            .info900 {
              font-family: Poppins, BR Firma;
              font-style: normal;
              font-weight: normal;
              font-size: 0.938em;
              line-height: 1.313em;
              color: #1e2329;
            }
            .infodata900 {
              font-family: Poppins, BR Firma;
              font-style: normal;
              font-weight: 500;
              font-size: 0.938em;
              line-height: 1.1875rem;
              color: #001f4b;
            }
          }
        }
      }
    }
  }

  .securitytab {
    &-header {
      p {
        color: #1e2329;
      }
    }

    .intrigeers1 {
      margin-top: 1.3125rem;
      p {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 600;
        font-size: 1em;
        line-height: 1.25rem;
        color: #1e2329;
      }
    }

    .securitylist {
      width: 37.5rem;
      padding-top: 1.25rem;
      .securitylist-items {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1.1875rem;
        padding-right: 1.25rem;
        margin-top: 1.25rem;
        border-bottom: 0.0625rem solid #edeff2;
        .first-side-1 {
          .first-side-11 {
            color: #1e2329;
            margin-bottom: 0.1875rem !important;
          }
          .first-side-12 {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: normal;
            font-size: 0.8125rem;
            line-height: 1rem;

            /* Type:/Description Text */

            color: #4f5d6d;
          }
        }
        .first-side-2 {
          display: flex;
          align-items: center;
          span {
            svg {
              width: 20px;
              margin-right: 1rem !important;
            }
          }
          .first-side-21 {
            color: #0056cd;
          }
          .first-side-22 {
            background: #dbf0da;
            border-radius: 0.25rem;
            color: #245421;
            padding: 0.25rem 0.3125rem;

            // display: flex;
            // align-items: center;
          }
        }
      }
    }

    .deleteaccountdiv {
      margin-top: 1.625rem;
      .zlo {
        color: #1e2329;
      }
      .deletesmessage {
        width: 33.125rem;
        margin-top: 1.5rem;
        p {
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: normal;
          font-size: 0.938em;
          line-height: 1.1875rem;

          color: #4f5d6d;
        }
      }

      .deletebutton {
        margin-top: 0.75rem;
        button {
          transition-duration: 0.6s;
          background: transparent;
          &:hover {
            background: rgba($color: #ee425a, $alpha: 0.2);
          }
          color: #ee425a;
        }
      }
    }
    .changepinish {
      margin-top: 2rem;
      .changepinish-t1 {
        margin: 1.875rem 0px !important;
        color: #1e2329;
      }
      .changepinish-t2 {
        margin-bottom: 2.5rem !important;

        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: normal;
        font-size: 0.9375rem;
        line-height: 1.1875rem;

        color: #4f5d6d;
      }
    }
    @media only screen and (max-width: 789px) {
      .securitylist {
        width: 100%;
      }
      .deleteaccountdiv {
        .deletesmessage {
          width: 100%;
          p {
            font-size: 0.9em;
          }
        }
      }
    }

    @media only screen and (max-width: 700px) {
    }
  }

  .billingtab {
    &-header {
      p {
        color: #1e2329;
      }
    }

    .no-payment {
      .p-bills-info {
        .p-bills5 {
          display: flex;
          align-items: center;
          border-bottom: 0.0625rem solid #edeff2;
          padding: 0.625rem 0rem;
          .p-bills5-1 {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 600;
            font-size: 1.625rem;
            line-height: 2.0625rem;
            color: #1e2329;
            margin-right: 0.3125rem !important;
          }
          .p-bills5-2 {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: #4f5d6d;
          }
        }

        .p-bills5000 {
          padding-top: 0.8rem;
          max-width: 500px;
          margin: 0 auto;
          .p-bills9 {
            display: flex;
            margin-bottom: 1.65rem;
            .p-bills91 {
              margin-right: 0.9375rem;
            }
            .p-bills92 {
              font-family: Poppins, BR Firma;
              font-style: normal;
              font-weight: normal;
              font-size: 1rem;
              line-height: 1.1875rem;
              color: #1e2329;
              @media only screen and (max-width: 37.25rem) {
                font-size: 0.875rem;
              }
            }
          }
        }
      }
    }

    .billingonMobile {
      display: none;
      .billingonMobile-items {
        border: 1.5px solid #edeff2;
        border-radius: 5px;
        .billingonMobile-t1 {
          display: flex;
          justify-content: flex-end;
          padding: 5px 18px;
          background: #ebeff4;
          border-radius: 5px 5px 0px 0px;
        }
      }
      .billingonMobile-b1 {
        display: flex;
        justify-content: space-between;

        padding: 10px 10px 10px 10px;

        .billingonMobile-bl1 {
          width: 65%;
        }
        .billingonMobile-br1 {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .billingonMobile {
    display: block !important;
    margin-bottom: 10px;
  }
  .settingspage .billingtab .payment101 .paymentstats {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

.notification-message {
  background-color: #ebf3ff;
  color: #1e2329;
  line-height: 19px !important;

  &.warning {
    background: #ffebee;
    color: #5d0814;
  }

  &.history {
    width: fit-content;
    margin-top: 15vh;
    text-align: center;
  }

  span {
    &:active {
      opacity: 0.8;
    }
  }

  svg {
    display: inline;
    margin-right: 8px;
  }
}

.toggle-switch label .inactive-track {
  display: block;
  height: 1.25rem;
  width: 2.75rem;
  background: #cdd7df;
  border-radius: 1.25rem;
  position: relative;
  margin-bottom: 0.9375rem;
  border: 0.0625rem solid #cdd7df;
}
.toggle-switch label .active-track {
  display: block;
  height: 1.25rem;
  width: 2.75rem;
  background: #0056cd;
  border-radius: 1.25rem;
  position: relative;
  margin-bottom: 0.9375rem;
  border: 0.0625rem solid #0056cd;
}

.toggle-switch .active-track:before,
.toggle-switch .inactive-track:before {
  content: "";
  display: inline-block;
  height: 1.1rem;
  width: 1.1rem;
  background: white;
  border-radius: 100%;
  position: absolute;
  top: 0rem;
  transition: right 0.2s ease-in;
}
.toggle-switch label .active-track:before {
  right: 0;
  left: 1.5rem;
}
.toggle-switch label .inactive-track:before {
  right: 1.5rem;
  left: 0;
}
.qr-code {
  width: 200px;
  height: 200px;
}
.qr-key {
  word-break: break-word;
}
.authApp-Modal {
  width: 620px !important;
}

.auth262 {
  height: calc(100% - 80px) !important;
  margin-bottom: 80px;
}

.cc-input {
  padding: 16px 12px;
}

.discount-code {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 20px 0;
  position: relative;

  &.add-space {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 586px) {
    display: block;
  }

  .remove-discount-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-right: 5px;
    }
  }

  .d-c-input {
    width: 100%;
    // width: calc(100% - 186px);
    // @media only screen and (max-width: 586px) {
    //   width: 100%;
    // }
  }

  .discount-slash {
    color: #4f5d6d;
    font-size: 12px;
    margin-top: 3px;
    position: absolute;
    @media only screen and (max-width: 586px) {
      font-size: 11px;
    }
  }

  .d-c-button {
    font-weight: 600;
    font-size: 14px;
    color: white;
    background: #0056cd;
    border-radius: 4px;
    width: 170px;
    padding: 13.5px 0;
    text-align: center;
    margin-left: 16px;

    &.remove {
      color: #4f5d6d;
      background: #ebeff4;
      cursor: default;
      @media only screen and (max-width: 586px) {
        margin-top: 24px;
      }
    }

    @media only screen and (max-width: 586px) {
      margin-left: unset;
      margin-top: 10px;
      padding: 10px 0px;
      width: unset;
    }
  }
}

.p-change-card {
  background: #ebf3ff;
  border: 1px solid #d6e7ff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 10px 16px;
  margin-bottom: 12px;

  .c-c-left {
    display: block;

    .l-top {
      color: #1e2329;
      font-weight: 500;
    }

    .l-bottom {
      color: #4f5d6d;
      font-size: 12px;
    }
  }

  .c-c-right {
    color: #0056cd;
    font-weight: 600;
  }
}

.delete-account {
  width: 40%;
  max-width: 695px;
  max-height: 90vh;
  min-width: 550px;
  background: #ffffff;
  border: 0.125rem solid #ffffff;
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 1.3rem 1.7375rem 1.675rem;
  .reasons-list {
    overflow-y: auto;
    height: calc(100% - 220px);
  }
  @media only screen and (max-width: 590px) {
    width: 100% !important;
    min-width: auto;
  }
}

.referral {
  .tab-desc {
    color: #4f5d6d;

    span {
      color: #0056cd;
    }
  }

  .referral-info {
    max-width: 868px;
    margin: 48px auto 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 568px) {
      display: block;
      background: #f5f9ff;
      border: 1px solid #ebf3ff;
      border-radius: 8px;
      padding: 20px 16px;
      margin: 32px auto;
    }

    .r-i-item {
      max-width: 212px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @media only screen and (max-width: 568px) {
        max-width: unset;
        flex-direction: row;
        text-align: left;
        align-items: flex-start;
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: unset;
        }

        svg {
          height: 48px;
          width: 48px;
          margin-right: 8px;
        }
      }

      .i-title {
        margin-top: 16px;
        margin-bottom: 8px;
        font-weight: 600;
        color: #1e2329;

        @media only screen and (max-width: 668px) {
          font-size: 14px;
          margin-top: unset;
          margin-bottom: 2px;
        }
      }

      .i-desc {
        font-size: 14px;
        line-height: 21px;
        color: #4f5d6d;

        @media only screen and (max-width: 668px) {
          font-size: 12px;
        }
      }
    }

    .r-divider {
      transform: translateY(33px);

      @media only screen and (max-width: 568px) {
        display: none;
      }
    }
  }

  .r-link {
    margin-bottom: 64px;

    .r-l-title {
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 20px;

      @media only screen and (max-width: 568px) {
        font-size: 16px;
        margin-bottom: 12px;
      }
    }

    .link-btn {
      display: flex;
      align-items: center;

      .l-b-text {
        padding: 12px 15px;
        border: 1px solid #edeff2;
        border-radius: 8px 0px 0px 8px;

        .sm-only {
          @media only screen and (min-width: 569px) {
            display: none;
          }
        }

        .lg-only {
          @media only screen and (max-width: 568px) {
            display: none;
          }
        }
      }

      .l-btn {
        border-radius: 0px 8px 8px 0px !important;
        margin-left: -2px;

        @media only screen and (max-width: 568px) {
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
      }
    }
  }

  .divider-full {
    width: 100vw;
    border-bottom: 1px solid #edeff2;
    margin-left: -16px;

    @media only screen and (min-width: 769px) {
      margin-left: -4.1875rem;
    }

    @media only screen and (max-width: 568px) {
      display: none;
    }
  }

  .referral-list-lg {
    margin-top: 32px;

    @media only screen and (max-width: 568px) {
      display: none;
    }

    .r-l-top {
      font-weight: 600;
      font-size: 20px;
    }

    .r201 {
      th {
        color: #4f5d6d !important;
      }

      .rth {
        text-align: right;
        padding-right: 30px;
      }
    }

    tbody {
      td:last-child {
        padding-right: 30px !important;
      }

      tr:last-child {
        background: #f6f7f9;
        height: 74px;
      }
    }

    .c-btn {
      min-width: 114px;
    }
  }

  .total-cl {
    color: #0056cd;
    font-weight: 600;
  }

  .referral-list-sm {
    @media only screen and (min-width: 569px) {
      display: none;
    }

    .amount-earned {
      box-shadow: 0px 9px 20px 6px rgba(157, 170, 184, 0.2);
    }
  }

  .claim-btn {
    color: #0056cd;
    font-weight: 600;
    border: 1px solid #0056cd;
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 4px;
    min-width: 114px;
  }
}

.ref-btn-wrapper {
  button {
    @media only screen and (min-width: 769px) {
      padding-left: 70px !important;
      padding-right: 70px !important;
    }
  }
}

.chose-cards-list {
  padding-bottom: 10px;
  max-height: calc(100% - 120px);

  &.new-payment {
    max-height: calc(100% - 190px);
  }
}

.select-circle {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  margin-right: 8px;
}

.circle-selected {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 86, 205, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 86, 205, var(--tw-border-opacity));
}

.circle-unselected {
  background: #ffffff;
  border: 1px solid #a1acaf;
}
