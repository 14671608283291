@supports (padding: env(safe-area-inset-bottom)) {
  .safe-bottom {
    padding-bottom: env(safe-area-inset-bottom) + 20px !important;
  }
}

@supports (padding: env(safe-area-inset-top)) {
  .safe-top {
      padding-top: calc(60px + env(safe-area-inset-top)) !important;
  }
}

.loggedin-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  border-top: 7px solid #0056cd;

  // Web
  &:not(.loggedin-container--native).has-notification {
    top: 36px;
  }  

  // Native
  &.loggedin-container--native {
    top: calc(86px - 36px);
  }

  &.loggedin-container--native:not(.has-notification) {
    border-top: none;
  }

  &.loggedin-container--native {
    &.has-notification {
      top: calc(86px + 7px);
    }
  }

  .sticky-notification {
    background: #ebf3ff;
    color: #1e2329;
    font-size: 14px;
    font-weight: 500;
    padding: 0 16px;
    height: 36px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .content {
      @media only screen and (max-width: 668px) {
        display: none;
      }
    }

    .action {
      color: #0056cd;
      text-decoration: underline;
      text-underline-offset: 0.25rem;
    }

    &.warning {
      background: #fcd9de;
    }
  }

  &.loggedin-container--native {
    .sticky-notification {
      top: 60px;
    }
  }

  .logged-m21 {
    .loggedin-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 3;
      padding: 0 20px;
      height: 70px;
      background-color: white;
      box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.08);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media only screen and (min-width: 1201px) {
        padding-left: 260px;
      }

      .h-right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 14px;

        .add-account-btn {
          padding: 8px 16px;
          background: #0056cd;
          border-radius: 4px;
          font-weight: 500;
          font-size: 14px;
          color: #ffffff;

          @media only screen and (max-width: 568px) {
            padding: 10px 11px;
          }

          span {
            @media only screen and (max-width: 568px) {
              display: none;
            }
          }
        }
      }
    }

    .main-section {
      position: absolute;
      top: 70px;
      right: 0;
      bottom: 0;
      left: 240px;
      overflow-y: scroll;
      font-size: 14px;

      @media only screen and (max-width: 1200px) {
        left: 0;
      }
    }
  }
}

