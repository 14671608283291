.vaultpage {
  .modll12 {
    display: flex;
    justify-content: center;
    margin-top: 8vh;
  }

  .filledstate90 {
    .header01 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #1e2329;
      }
    }

    .fileslist00 {
      .header112 {
        margin-bottom: 1.875rem;
        margin-top: 1.25rem;

        p {
          color: #1e2329;
        }
      }
    }

    .singleli1top {
      display: flex;
      margin-bottom: 1rem;
      justify-content: space-between;
    }

    .singleli1bottom {
      .singleli1bottom-name {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 500;
        font-size: 0.813em;
        line-height: 1rem;
        color: #1e2329;
        margin-bottom: 0.875rem !important;
        word-break: break-all;
      }

      .singleli1bottom-na4 {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 0.9375rem;

        .t11 {
          color: #1e2329;
        }

        .t22 {
          color: #4f5d6d;
        }
      }
    }
  }
}

.fofof {
  margin-left: 0rem !important;
}

.securevault {
  width: 36.25rem;
  background: #ffffff;

  @media screen and (min-width: 550px) and (min-height: 400px) and (max-height: 700px) {
    height: 100%;
  }

  @media only screen and (max-width: 400px) {
    height: 100%;
  }

  border: 0.125rem solid #ffffff;
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 1.75rem 1.9375rem 1.875rem;

  .securevault-s1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .securevault-s2 {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 1rem !important;
  }

  .securevault-s3 {
    margin-bottom: 1rem !important;
    text-align: center;
    color: #1e2329;
  }

  .securevault-s4 {
    margin-bottom: 3.75rem !important;
    text-align: center;
    color: #4f5d6d;
  }

  .securevault-s6 {
    margin-bottom: 1.4375rem !important;
  }

  .pinlabel {
    color: #4f5d6d;
    margin-bottom: 1.125rem !important;
  }

  .header2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;

    .header2-in {
      color: #1e2329;
    }
  }

  .saveitop {
    display: flex;
    justify-content: flex-end;

    .cancel {
      background: #ebeff4;
      color: #4f5d6d;
      margin-right: 1.125rem;
    }
  }

  .securevault-d6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .securevault-d7 {
    margin-bottom: 1.6875rem !important;
  }

  .securevault-d8 {
    margin-bottom: 1rem !important;
    text-align: center;
    color: #1e2329;
  }

  .securevault-d9 {
    margin-bottom: 2.75rem !important;
    text-align: center;
    color: #4f5d6d;
  }
}

.dfit-the-width {
  width: 28.25rem !important;
}

@media only screen and (max-width: 400px) {
  .dfit-the-width {
    width: 100% !important;
  }
}

.vault-file-icon {
  p {
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 475px) {
  .btn-text-hide {
    display: none;
  }
}

.modal-width {
  width: 590px !important;
}