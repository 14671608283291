.authpage.authpage--native {
  border-top: none;
}

.authpage {
  border-top: 7px solid #0056cd;

  .divideauthpage {
    margin: auto;
    display: flex;
    min-height: calc(100vh - 95px);
    align-items: center;
    position: relative;

    .firsthalf {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      min-height: calc(100vh - 10px);

      height: 100%;
      width: 50%;
      background: #ebf3ff;
      padding: 1rem 1rem 0 1rem;
      margin-top: 0vh;
      position: relative;

      .mockup {
        position: absolute;
        width: 100%;
        max-width: 580px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .banner {
        position: absolute;
        max-width: 332px;
        width: 70%;
        bottom: 75px;
        right: -35px;
      }
      .bottom {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        max-height: 850px;
      }

      .block {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        width: fit-content;

        .heading {
          color: #001f4b;
          font-weight: 600;
          font-size: 42px;
          line-height: 63px;
          max-width: 490px;
          width: 100%;
          padding: 0rem 0rem 1rem 0rem;
        }

        .blue-text {
          color: #0056cd;
        }

        .text {
          padding: 0.5rem 0rem;
          font-size: 16px;
          line-height: 28px;
          color: #001f4b;
          max-width: 442px;
          width: 100%;
          margin-bottom: 64px !important;
        }

        .secure-box {
          margin: 1.5rem 0rem;
          background: #fff;
          max-width: 465px;
          border: 1px solid #0056cd;
          border-radius: 6.63566px;
          padding: 14px 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .secure-text {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            width: 100%;
            padding-left: 20px;
            max-width: 320px;
          }

          img {
            width: 82.95px;
            height: 82.95px;
            margin-right: 0.5rem;
          }
        }
      }

      .rate {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        max-width: 455px;
        width: 100%;
        margin-bottom: 68px;

        .rate-text {
          font-weight: 400;
          font-size: 14px;
          max-width: 383px;
          line-height: 21px;
          color: #001f4b;
          margin-bottom: 10px !important;
        }

        .user-section {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .user {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 18px;
          }

          .star-section {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;
          }
        }
      }
    }

    .secondhalf {
      flex: 1;
      display: flex;
      justify-content: center;
      padding: 0 2rem;
    }
  }
  .forgotpassword {
    display: flex;
    justify-content: flex-start;
    height: calc(100vh - 7px);

    .inner {
      width: 100%;
      &.portfolio {
        max-width: 672px;
        padding: 30px 20px;
        border: 1px solid #edeff2;
        box-sizing: border-box;
        border-radius: 8px;
      }
    }

    .h3-size {
      color: #1e2329;
    }

    .btn-md {
      width: 245px;
    }

    .message {
      color: #1e2329;
      margin-top: 57px !important;
      margin-bottom: 15px !important;
    }
  }
}

.pincode-input-container {
  display: flex;
  justify-content: space-between;

  .pincode-input-text {
    padding: 0 !important;
    margin: 0 2px !important;
    text-align: center !important;
    width: 50px !important;
    height: 50px !important;
    background: #f6f7f9 !important;
    border: 1px solid #edeff2 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
  }
  .pincode-input-text:focus {
    outline: none;
    box-shadow: none;
  }
}

@media only screen and (max-width: 768px) {
  .divideauthpage {
    .firsthalf {
      display: none !important;
      flex: 1 !important;
      margin-top: 7vh !important;
    }

    .secondhalf {
      padding: 2rem 2rem !important;
      height: 100vh !important;
      align-items: center !important;
    }

  }
}

.terms_list_body_contaner {
  padding: 40px 50px;
  max-width: 1000px;
  margin: auto;
}

ul.terms_list_body_contaner,
ul.terms_body {
  list-style-type: none;
  counter-reset: terms-counters 0;
  li {
    position: relative;
  }
  & > li::before {
    position: absolute;
    left: 1rem;
    line-height: 28px;
    font-size: 22px;
    font-family: Poppins, BR Firma;
    height: 22px;
    counter-increment: terms-counters;
    content: counters(terms-counters, ".");
    @media only screen and (max-width: 768px) {
      left: -1rem;
    }
  }
}

ul.terms_body {
  li {
    padding-left: 2.5rem;
    &::before {
      left: 0;
      font-size: inherit;
      line-height: inherit;
    }
  }
}

.terms_intro {
  background: #ebf3ff;
  padding: 2rem 0 3rem 0;

  @media only screen and (max-width: 768px) {
    padding: 4rem 0 3rem 0;
  }

  .terms_container {
    h1,
    p {
      max-width: 60%;
      @media only screen and (max-width: 768px) {
        max-width: 100%;
      }
    }

    h1 {
      font-weight: bold;
      font-size: 32px;
      margin-bottom: 1rem !important;

      span {
        &:first-of-type {
          color: #0056cd;
        }
        &:nth-of-type(2) {
          color: #00b0ef;
        }
      }
    }
  }
}

.terms_container {
  padding: 0 50px;
  @media only screen and (max-width: 768px) {
    padding: 0 15px;
  }
  max-width: 1440px;
  margin: auto;
}

.terms_head {
  font-family: Poppins, BR Firma;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 1rem !important;
  counter-reset: css-counters 0;
  position: relative;
}

.terms_head::before {
  counter-increment: css-counters;
  content: 2 !important;
  position: absolute;
  top: 0;
  left: 0;
}

.terms_subhead {
  font-weight: 500;
  margin-bottom: 0.5rem !important;
}

.terms_body {
  font-weight: 300;
  margin-bottom: 1rem !important;
}

.terms_subhead,
.terms_body {
  font-family: Poppins, BR Firma;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

div .terms_container {
  &:not(:last-of-type) {
    margin-bottom: 4.375rem;
  }
}

.terms_get_started {
  width: 100%;
  height: fit-content;
  padding-top: 4rem;
  padding-bottom: 4rem;
  color: white;
  background: #0056cd;
  margin-top: 4rem;

  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      display: block;
    }
    .content {
      width: 60%;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      font-family: Poppins, BR Firma;
      text-align: left;
      font-style: normal;
      h4 {
        font-size: 28px;
        font-weight: 600;
        line-height: 35px;
        letter-spacing: 0em;
      }
      p {
        font-size: 18px;
        font-weight: 300;
        line-height: 23px;
        letter-spacing: 0em;
        margin-top: 26px;
      }
    }
    .button {
      @media only screen and (max-width: 768px) {
        margin-top: 26px;
      }
      a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 18px 30px;
        width: 220px;
        height: 56px;
        background: #ffffff;
        border-radius: 5px;

        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #001f4b;
      }
    }
  }
}

.header-wrapper {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.08);
}
