$viewports: (
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1536px
);

// Updates
.modal-container {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100vh;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 0;


  @media only screen and (min-width: map-get($viewports, 'sm')) {
    max-width: 670px;
    max-height: 670px;
    border-radius: 10px;

    &--sm {
      max-width: 370px;
      max-height: 370px;
      border-radius: 10px;
    }
  }
}

.modal-container-overlay {
  position: fixed;
  top: 0rem;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Base styles - starting with smaller viewport sizes */
.modal-header {
  height: 40px; /* Smaller height for small screens */
}

.modal-body {
  height: calc(100% - 120px);
  overflow-y: auto;
}

.modal-footer {
  height: 80px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

/* Medium screens and larger */
@media (min-width: 768px) {
  .modal-header {
    height: 60px; 
  }

  .modal-body {
    height: calc(100% - 130px);
  }

  .modal-footer {
    height: 70px;
  }
}


// Legacy -----------------------------------------------------------
.Toastify__toast-body {
  width: 100%;
}

.Toastify__toast-container {
  min-width: 360px;
}

.custom-toast-success {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 12px 16px;
}

.successnot {
  display: flex;
  align-items: center;

  .successnot-inner-icon {
    svg {
      width: 33px;
    }
  }

  .successnot-inner-mess {
    color: #1e2329;
    margin-left: 12px !important;
    font-size: 15px;
    line-height: 18px;
  }
}

.confirmationModal,
.moveAssetModal,
.assetHistoryModal {
  background: #ffffff;
  width: 750px;
  border: 0.125rem solid #ffffff;
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 1.75rem 1.9375rem 1.875rem;

  @media only screen and (max-width: 550px) {
    height: 100% !important;
    max-width: 100%;
    max-height: 100vh !important;
    width: 100%;
    border-radius: 0rem;
    padding-left: 1.1875rem;
    padding-right: 1.25rem;
  }

  form {
    .form-control {
      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }

      input {
        background: #f6f7f9;
        border: 1px solid #1e2329;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
  }
}

.confirmationModal {
  width: 571px !important;
}

.assetHistoryModal {
  height: 75vh;
  max-height: 700px;

  &.portfoliosettings {
    max-height: 600px;
  }

  .viewassets-tabs {
    height: calc(100% - 150px);

    .react-tabs {
      height: 100%;

      .react-tabs__tab-panel {
        height: calc(100% - 50px);
        overflow-y: scroll;
      }
    }

    .react-tabs__tab {
      height: 27px;
      box-sizing: border-box;
      padding: 3px 16px !important;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .viewassets-tabs.history-modal {
    padding-bottom: 50px;

    &.no-overflow {
      .react-tabs__tab-panel {
        overflow-y: hidden !important;
      }
    }
  }
}