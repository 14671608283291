.holdings {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }

  .holdings-table {
    width: 100%;
    text-align: left;
    overflow-x: scroll;
    word-wrap: break-word;

    ::-webkit-scrollbar {
      height: 5px !important;
    }

    ::-webkit-scrollbar-thumb {
      background: #a1acaf;
      border-radius: 2px;
    }

    ::-webkit-scrollbar-track {
      background-color: #edeff2;
      border-radius: 2px;
    }

    .mobile-grid {
      display: grid;
      grid-template-columns: repeat(7, minmax(150px, 1fr));

      > p {
        border-bottom: 1px solid #edeff2;
        padding: 10px;
      }
    }

    .row-header {
      p {
        background: #edeff2;
        font-size: 14px;
        color: #4f5d6d;
        margin-right: 20px;
      }
    }

    .row-content p {
      color: #1e2329;
      font-weight: 500;
    }

    .fixed-content {
      border-bottom: 1px solid #edeff2;
      min-width: 150px;
      max-width: 150px;
      position: sticky;
      left: 0px;
      z-index: 10;

      p {
        padding: 10px;
      }
    }

    .shadow {
      box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    }
  }
}
