.placecenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.danger {
  color: #ee425a;
}

.danger-not {
  color: #5d0814;
  background: #fcd9de;
}

.notification {
  border-radius: 0.3125rem;
  padding: 0.7rem 1rem;
}
