@mixin font-face($weight: 500, $style: normal) {
  font-weight: $weight;
  font-style: $style;
  font-family: Poppins, BR Firma;
}

@mixin font-look($font-size, $line-height) {
  font-size: $font-size;
  line-height: $line-height;
}

@mixin font-look-mediaQuery($font-size, $line-height, $screen-width: 600px) {
  @media only screen and (max-width: $screen-width) {
    font-size: $font-size;
    line-height: $line-height;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0rem !important;
}

.h1-size {
  @include font-face(500);
  @include font-look(2.25em, 2.625rem);
}

.h2-size {
  @include font-face(600);
  @include font-look(1.75em, 2.1875rem);
  @include font-look-mediaQuery(1.25em, 1.4375rem);
}

.h3-size {
  /* H3 */
  @include font-face(500);
  @include font-look(1.5em, 1.875rem);
  @include font-look-mediaQuery(1.125em, 1.4375rem);
}

.h4-size {
  /* H4 */
  @include font-face(500);
  @include font-look(1.125em, 1.4375rem);
  /* identical to box height */
}

.h4-semibold {
  /* H4 SemiBold */
  @include font-face(600);
  @include font-look(1.25em, 1.5625rem);
  @include font-look-mediaQuery(0.9375rem, 1.0625rem);
}

.h5-semibold {
  /* H5 SemiBold */
  @include font-face(500);
  @include font-look(1.125em, 1.4375rem);
  @include font-look-mediaQuery(0.875rem, 1rem);
  color: #c4c4c4;
}

.p-big {
  /* Paragraph */
  @include font-face(normal);
  @include font-look(0.9375rem, 1.1875rem);
  @include font-look-mediaQuery(0.8125rem, 1rem);
}

.p-normal {
  /* Paragraph */
  @include font-look(0.938em, 1.25rem);
  @include font-look-mediaQuery(0.875rem, 1.125rem);
}

.p-medium {
  /* Paragraph Medium */
  @include font-face(500);
  @include font-look(1em, 1.25rem);
  @include font-look-mediaQuery(0.875rem, 1rem);
}

.p-semibold {
  /* Paragraph Semibold */
  @include font-face(600);
  @include font-look(1em, 1.25rem);
  @include font-look-mediaQuery(0.875rem, 1rem);
}

.helper-text {
  /* Helper Text */
  @include font-face(500);
  @include font-look(0.75rem, 0.9375rem);
  /* identical to box height */
}

.p-mobile {
  /* Paragraph Mobile */
  @include font-face(normal);
  @include font-look(0.875rem, 1.125rem);
  /* identical to box height */
}
