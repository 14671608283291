.ReactModal__Overlay {
    opacity: 0;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transition: opacity 200ms ease-out;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transition: opacity 150ms ease-in;
}

// Handle native safe area insets
@supports (padding: env(safe-area-inset-top)) {
    .ReactModal__Content.native-modal {
        padding-top: calc(50px + env(safe-area-inset-top));
    }
}

@supports not (padding: env(safe-area-inset-top)) {
    .ReactModal__Content.native-modal {
        padding-top: 50px;
    }
}
