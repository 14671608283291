.reconnect-btn button {
  padding: 6px 10px !important;
}
.reconnect-err {
  color: #ae2c3e;
  display: block;
  font-size: 11px;
}
.acc-name {
  font-size: 11px;
  display: block;
}
.scrollcontainer {
  max-height: 60%;
  overflow-y: auto;
  padding-right: 10px;
}
