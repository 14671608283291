.viewassetsdetails {
  width: 670px;
  height: 80%;
  max-height: 670px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* White */

  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 12px 24px 45px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-assetsheader {
      color: #1e2329;
    }
  }
  .viewassets-tabs {
    padding-top: 10px;
    height: 100%;

    .viewdetails {
      .assetsdata {
        margin-top: 30px;
        &-header {
          color: #1e2329;
        }
        &-subheader {
          margin-top: 10px !important;
          color: #4f5d6d;
        }
      }
    }
  }

  .bottombottons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    .cancel {
      background: #ebeff4;
      color: #4f5d6d;
      margin-right: 10px;
    }

    button:focus {
      outline: none;
    }
  }

  .files-tab {
    height: 100%;
    padding-top: 13px;

    .dragitemsinto {
      background: #f6f7f9;
      border: 1px dashed #abafaf;
      box-sizing: border-box;
      border-radius: 5px;
      height: 63%;

      .message3 {
        p {
          color: #abafaf;
        }
      }

      .dragdrop {
        margin: 15px auto 0 !important;
        text-align: center;
        font-size: 0.75rem;
        color: #4f5d6d;
        width: 80%;
        span {
          color: #0056cd;
        }
      }
    }

    .dragitemsinto:hover {
      background: #ebf3ff;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    position: unset;
    border-radius: 0px;
    padding: 10px 15px 0px;
    transform: translate(0%);
  }
}

.viewassetsdetails:focus {
  outline: none;
}

.asset-history-select {
  width: 7.4375rem;
  height: 2.5rem;
  background: #f6f7f9;
  border: 0.0625rem solid #edeff2;
  box-sizing: border-box;
  border-radius: 0.25rem;
  padding: 0.4rem 0.8rem;
  margin-bottom: 12px;
}

.planchangemodal {
  width: 670px;
  height: 80%;
  max-height: 570px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 12px 24px 45px;

  .btn-control {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 550px) {
    .btn-control {
      gap: 16px;
      flex-direction: column-reverse;
    }
  }
}
