.forminput {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 0.25rem;
    }

    .lefticoninput {
      display: flex;
      align-items: center;
      background: #f6f7f9;
      border: 0.0625rem solid #edeff2;
      box-sizing: border-box;
      border-radius: 0.25rem;
      padding: 0.8rem 0.8rem;

      .iconinput {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.25rem;
        color: #1e2329;
        background: none;
        width: 92%;
        border: none;
        padding: 0;
      }

      .iconinput:focus {
        outline: none !important;
        border: 0px solid #000 !important;
      }
    }

    .lefticoninput:focus-within {
      box-sizing: border-box;
      border-radius: 0.25rem;
      outline: none;
    }

    .simpleinput {
      background: #f6f7f9;
      border: 0.0625rem solid #edeff2;
      box-sizing: border-box;
      border-radius: 0.25rem;
      padding: 0.8rem 0.8rem;
      font-family: Poppins, BR Firma;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.25rem;
      color: #1e2329;
    }

    .simpleinput:focus {
      box-sizing: border-box;
      border-radius: 0.25rem;
      outline: none;
    }

    .hidefocus {
      border: none !important;
      box-sizing: border-box;
      border-radius: 0.25rem;
      outline: none;
    }

    .selectfield {
      background: #f6f7f9;
      border: 0.0625rem solid #edeff2;
      box-sizing: border-box;
      border-radius: 0.25rem;
      height: 2.8125rem;
      padding: 0rem 0.625rem;
    }

    .select2 {
      width: 30% !important;
    }

    .selectfield:focus {
      outline: none;
    }
  }

  .form-control {
    margin-bottom: 1.875rem;

    @media only screen and (max-width: 768px) {
      margin-bottom: 1.5625rem;
    }
  }

  .checkbox {
    border-radius: 0.125rem;
    margin-right: 0.625rem;
  }

  .checkboxlabel {
    color: #1e2329;
  }

  .errormessage {
    margin-top: 0.625rem;
  }

  .errordiv {
    border: 0.0625rem solid #ee425a !important;
  }

  .svgwhite {
    margin-left: 0.5rem;

    svg {
      path {
        stroke: white;
      }
    }
  }

  .assnewassetsoptions {
    padding: 1.125rem 1.25rem;
  }

  .assnewassetsoptionsdown {
    right: 0rem;
    background: white;
    padding: 0.625rem;
    display: flex;
    flex-direction: column;
    width: 10.5rem;
    box-shadow: 0rem 0.375rem 1.25rem rgba(0, 0, 0, 0.2);
    border-radius: 0.3125rem;
    margin-top: 0.625rem;
    z-index: 40;

    .optionsa {
      padding: 0.625rem;
      font-family: Poppins, BR Firma;
      font-style: normal;
      font-weight: normal;
      font-size: 0.9375rem;
      line-height: 1.1875rem;
      color: #1e2329;
    }

    .optionsa:hover {
      background: #ebf3ff;
    }

    .delete:hover {
      background: rgba($color: #ee425a, $alpha: 0.1) !important;
    }

    .active {
      background: #ebf3ff;
    }
  }
