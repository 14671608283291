.dashboard {
  min-height: 100vh;

  .chartdata {
    border-radius: 8px;
  }

  @media only screen and (max-width: 767px) {
    padding: 1rem;
    background: #ffffff;

    .chartdata {
      border: 1px solid #edeff2;
      padding: 1rem !important;
    }
  }
}

@media only screen and (min-width: 590px) {
  .useronboarding {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    position: relative;
    .row-one {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .text {
          margin: auto 20px;
          .title {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 0.75rem;
            color: #1e2329;
            margin-bottom: 0.5625rem;
          }
          .description {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 1.1875rem;
            color: #4f5d6d;
          }
        }
        .completed {
          margin: auto 0;
          color: #245421;
          background-color: #dbf0da;
          border-radius: 0.25rem;
          padding: 0.313rem;
          position: absolute;
          top: 45px;
          right: 12px;
        }
      }

      .right {
        font-size: 0.8rem;
        color: #4f5d6d;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
        position: absolute;
        top: 12px;
        right: 12px;
        &:active {
          opacity: 0.7;
        }
      }
    }

    .row-two {
      display: flex;
      flex-direction: row;
      margin-top: 1.875rem;
      flex-wrap: wrap;
      .onboardingstep {
        display: flex;
        flex-direction: row;
        width: 190px;
        padding-bottom: 10px;
        .iconwrapper {
          margin-right: 10px;
          .checkicon {
            min-height: 1.8rem;
            min-width: 1.8rem;
          }
        }
        .stepinfo {
          display: block;
          .action {
            font-weight: 600;
            font-size: 0.875rem;
            color: #0056cd;
            margin-bottom: 0.5rem;
            cursor: pointer;
            &:active {
              opacity: 0.7;
            }
          }
          .desc {
            font-size: 0.7rem;
            color: #4f5d6d;
          }
        }
      }
    }

    .continue-mobile {
      display: none;
    }
  }
}

@media only screen and (max-width: 589px) {
  .useronboarding {
    padding: 0 0.5rem;
    display: block;
    position: fixed;
    top: 75px;
    left: 0;
    bottom: 0;
    right: 0;
    padding-bottom: 1rem;
    overflow-y: scroll;
    width: 100%;
    z-index: 3;
    background-color: #ffffff;
    &.extra-space {
      top: 115px !important;
    }
    .row-one {
      padding: 1.5rem 0;
      width: 100%;
      display: block;
      text-align: center;
      border-bottom: 1px solid #e1e7ec;
      .left {
        display: block;
        .manicon {
          margin: 0 auto;
          padding-bottom: 10px;
        }
        .text {
          margin-top: 1rem;
          .title {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 0.75rem;
            color: #1e2329;
            margin-bottom: 0.7rem;
          }
          .description {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 1.1875rem;
            color: #4f5d6d;
          }
        }
        .completed {
          display: none;
        }
      }

      .right {
        display: none;
      }
    }

    .row-two {
      display: block;
      margin-top: 1.5rem;
      padding-left: 0.5rem;
      border-bottom: 1px solid #e1e7ec;
      .onboardingstep {
        display: flex;
        flex-direction: row;
        padding-bottom: 20px;
        .iconwrapper {
          margin-right: 10px;
          .checkicon {
            min-height: 1.8rem;
            min-width: 1.8rem;
          }
        }
        .stepinfo {
          display: block;
          .action {
            font-weight: 600;
            font-size: 0.875rem;
            color: #0056cd;
            margin-bottom: 0.3rem;
            cursor: pointer;
            &:active {
              opacity: 0.7;
            }
          }
          .desc {
            font-size: 0.7rem;
            color: #4f5d6d;
          }
        }
      }
    }

    .continue-mobile {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;

      &:active {
        opacity: 0.7;
      }
    }
  }
}
