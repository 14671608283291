.manage-labels {
  max-height: 70%;
  overflow-y: auto;
  padding-right: 8px;
}
.svg-size svg {
  margin-top: 24px;
}
.info {
  max-width: 400px;
  margin: auto;
  text-align: center;
}

.accounts-label {
  max-height: 190px;
  @media only screen and (max-width: 768px) {
    max-height: 48vh;
  }
}
.accounts-label-new {
  max-height: 260px;
  @media only screen and (max-width: 768px) {
    max-height: 48vh;
  }
}

.acct-labels {
  @media screen and (max-width: 768px) {
    max-height: 40vh;
    padding-right: 8px;
    padding-bottom: 30px;
    overflow-y: auto;
  }
}

.delete-btn {
  filter: brightness(0);
  margin-left: 15px;
}
