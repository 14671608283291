.sidebar-ham {
    @media (min-width: 1200px) {
        display: none;
    }
}

.loggedin-container--native:not(.has-notification) .sidebar {
    top: -56px;
    border-top: 56px solid #0045a4;
}

.loggedin-container--native.has-notification .sidebar {
    top: -86px - 14px;
    border-top: 56px solid #0045a4;
}

.sidebar {
    z-index: 99 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh + 256px) !important;
    opacity: 1;
    visibility: hidden;
    transform: translateX(-100%);
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;

    &.sidebar--open {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
        width: 400px;
    }

    @media (min-width: 1200px) {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
    }

    .sidebar-close-btn {
        @media (min-width: 1200px) {
            display: none;
        }
    }
}