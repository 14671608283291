.btn {
  padding: 6px 12px;
  svg {
    width: 13px;
  }
  @media only screen and (min-width: 768px) {
    padding: 8px 16px;
    svg {
      width: 18px;
    }
  }
}
.btn-bg {
  background-color: #ebeff4;
}
.collab-header {
  margin-bottom: 25px;
  @media only screen and (min-width: 768px) {
    margin-bottom: 36px;
  }
}
.sidebarcollab {
  border-top: 1px solid #e1e7ec;
}
.sidebar-dropdown {
  margin-top: -7px;
}
.sidebar-dropdown-head {
  font-size: 10px;
  line-height: 22px;
  color: #a1acaf;
}
.svg-rotate {
  svg {
    transform: rotate(180deg);
  }
}

.collaborations {
  .active-2 {
    .collab-name {
      color: #1e2329 !important;
    }
  }
}

.active-2 {
  background: #ebf3ff !important;
}

.cssdjuyt {
  border: 1px solid #a1acaf;

  input {
    box-shadow: unset !important;
    padding: 5px 0px !important;
  }
  .css-tlfecz-indicatorContainer {
    color: #1e2329;
  }
}

.lock98 {
  transform: translateY(-3px);
}
.css-4qxkjs-container {
  width: 100% !important;
}

.c-wrapper349 {
  &:nth-last-child(1) {
    > div {
      margin-bottom: unset !important;
    }
  }
}
