.collaboration {
  .description {
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    align-items: flex-end;
    svg {
      width: 213px;
    }
    .desc-text {
      width: calc(100% - 213px);
    }
    p {
      max-width: 815px;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;

      .desc-text {
        width: 100%;
      }
      svg {
        width: 115px;
      }
      h2 {
        font-size: 24px !important;
      }
      p {
        font-size: 12px !important;
      }
    }
  }
  .collaborationheader {
    p {
      max-width: 468px;
    }
    .collabview {
      display: flex;
      button {
        background: #f6f7f9;
        border-radius: 30px;
        padding: 3px 16px;
        margin-right: 32px;
      }
      .active-btn {
        background: #d6e7ff;
        color: #0056cd;
      }
    }
  }
  .shareportfolio {
    margin: 56px 0 62px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    p {
      max-width: 555px;
    }
  }
  .collaborators {
    border: 1px solid #edeff2;
    .table-data {
      display: grid;
      grid-template-columns: 28% 17% 15% 15% 25%;
      align-items: center;
      padding: 18px 16px;
    }
    .body {
      border: 1px solid #edeff2;
    }
    .status {
      width: fit-content;
      padding: 4px 12px;
      border-radius: 8px;
    }
  }

  .portfolio-invite {
    min-height: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 16px;
      box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.08);
      border-top: 7px solid #0056cd;
    }
    .desc {
      padding: 24px;
      max-width: 672px;
      border: 1px solid #edeff2;
      border-radius: 8px;
      button {
        display: block;
        margin: 42px auto 54px;
        width: 75%;
      }
    }
  }
  .mobile-collaborator {
    display: none;
    .collabcontainer {
      border-radius: 8px;
      .displaypicture {
        width: 48px;
        height: 48px;
        border-radius: 99px;
      }
    }
  }
  .mobile-share-btn {
    display: none;
    background-color: #0056cd;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 50px;
    right: 25px;
    animation: bounce 0.6s infinite alternate;
    box-shadow: 0px 4px 20px rgba(0, 69, 164, 0.3);
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  @media screen and (max-width: 768px) {
    padding: 1rem;
    .shareporfolio {
      h3 {
        font-size: 18px !important;
      }
    }
    .description {
      padding: 1rem;
      flex-direction: column;
      .desc-text {
        width: 100%;
      }
      svg {
        width: 115px;
      }
      h2 {
        font-size: 24px !important;
      }
      p {
        font-size: 12px !important;
        span {
          display: none;
        }
      }
    }
    .collaborationheader {
      > button {
        display: none !important;
      }

      button {
        margin-right: 10px !important;
      }
    }
    .collaborators {
      display: none;
    }
    .mobile-collaborator {
      display: block;
    }
    .mobile-share-btn {
      display: flex;
    }
  }
}

.accepted {
  background: #dbf0da;
  color: #245421;
}
.pending {
  background: #edeff2;
  color: #4f5d6d;
}
.declined {
  background: #fcd9de;
  color: #5d0814;
}

.mobile-status {
  font-size: 8px;
  padding: 2px 8px;
  width: fit-content;
  border-radius: 4px;
}
