.join-cova-modal {
  width: 100%;
  background: #ffffff;
  padding: 24px;
  max-width: 502px;
  border: 1px solid #e1e7ec;
  border-radius: 8px;
}

.err-deck-1 {
  display: flex;
  --tw-text-opacity: 1;
  color: rgba(30, 35, 41, var(--tw-text-opacity));
  margin-bottom: 0.25rem /* 4px */;
  font-weight: 500;
  align-items: center;
  font-size: 0.75rem /* 12px */;
  line-height: 1.125rem /* 18px */;
  justify-content: flex-start;
}
