.categories {
  .divider-xt2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .d-line {
      width: 45%;
      border-top: 1px solid #dfe1e5;
    }
  }
}
