.Toastify__toast {
    box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.15) !important;
    background: #fff !important;
}

.Toastify__toast--default,
.Toastify__toast--info,
.Toastify__toast--success,
.Toastify__toast--warning {
    background: #fff !important;
    color: #000 !important;
}

.Toastify__toast--error {
    background: #fcd9de !important;
    color: #5d0814 !important;

    .Toastify__toast-body {
        background: #fcd9de !important;
    }
}

.Toastify__toast-body {
    background: #fff !important;
    font-family: Poppins !important;
    font-size: 0.875rem;
}

.Toastify__close-button,
.Toastify__close-button:hover,
.Toastify__close-button:focus {
    color: #fff !important;
}

.Toastify__progress-bar {
    background-color: #fff !important;
    color: #fff;
}

.toast-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.toast-div img {
    width: 2rem;
    height: 2rem;
}


// Use for native apps
.Toastify__toast-container.Toastify--native {
    top: 130px;
    padding: 0 1rem;
}