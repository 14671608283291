.controller {
  height: 80%;
  .permission {
    .access {
      position: absolute;
      top: 7.7px;
      right: -24.5px;
      width: 120px;
    }
    .select-permision {
      box-shadow: 0px 9px 20px 6px rgba(79, 93, 109, 0.2);
      border-radius: 4px;
      background-color: white;
      padding: 8px;
      position: absolute;
      top: 38px;
      right: 12px;
      width: 120px;
      button {
        font-size: 14px;
        padding: 8px;
        width: 100%;
        text-align: left;
        &:hover {
          background-color: #ebf3ff;
          border-radius: 4px;
        }
      }
    }
  }
  .mails {
    div {
      background: #d6e7ff;
      border-radius: 4px;
      padding: 6px 8px;

      span {
        border-radius: 50%;
        width: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 21px;
        padding: 8px;
        border: 1.2px solid #0045a4;
      }
    }
  }
}
.select-portfolio {
  .labels {
    border: 1px solid #edeff2;
    border-radius: 4px;
    overflow: auto;
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px;
      svg {
        margin-right: 8px;
      }
      input {
        display: none;
      }
    }
  }
  .others {
    padding: 12px;
    height: 45vh;
    max-height: 24rem;
    @media screen and (max-width: 768px) {
      height: 62vh;
      @media screen and (max-width: 550px) {
        max-height: initial;
      }
    }
  }
  .edit-other-labels {
    height: 34vh;
    max-height: 19rem;
    padding: 12px;
    @media screen and (max-width: 550px) {
      height: 54vh;
      @media screen and (max-width: 550px) {
        max-height: initial;
      }
    }
  }
}
.buttons {
  span {
    filter: brightness(0) invert(100%);
    padding-right: 6px;
  }
}
